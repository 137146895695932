import React from 'react'
import './Description.css'

interface DescriptionProps {
    language: string;
    text: string;
}

export const Description = (
    {
        language,
        text,
    }: DescriptionProps
) => (
    <section className='studio'>{text ? text : `text for language "${language}" not found`}</section>
)