import React, { useState, useEffect, useMemo } from 'react'
import {
	Text,
	Service,
	Seo,
	AboutProps,
	ProjectProps,
	AllServices
} from '../types'
import { MainLayout } from '../components/layouts/Main'
import { SEO as SeoPage } from '../components/ui-components/Seo'
import { Slider } from '../components/ui-components/slider'
import { Description } from '../components/ui-components/index/Description'
import { Service as ServicePage } from '../components/ui-components/index/Service'
import { About } from '../components/ui-components/index/About'
import { MainTeam } from '../components/ui-components/about/MainTeam'
import { getMainTeamTexts, getTextByKey } from '../utils'
import { WINDOW_MOBILE_WIDTH } from '../common/constants'
import { ProjectList } from '../components/ui-components/projects/ProjectList'
import { PriceForm } from '../components/ui-components/forms'

const IndexTemplate = (
	{
		pageContext: {
			sliderPictures,
			texts,
			language,
			services: allServices,
			meta,
			siteImages,
			projects,
            categories,
		}
	},
) => {
	const [ windowWidth, setWindowWidth ] = useState(727)

	const services: AllServices = useMemo(() => (
		allServices.reduce((total: AllServices, service: Service) => ({
			...total,
			[service.key]: {
				title: service.title,
				value: service.value
			},
		}), {})
	), [allServices])

    const about: AboutProps = useMemo(() => ({
        header: texts.find((text: Text) => text.key === 'about-header').value,
        info: texts.find((text: Text) => text.key === 'about-page-text').value,
        author: texts.find((text: Text) => text.key === 'about-text-author').value,
        button: texts.find((text: Text) => text.key === 'about-button-more').value,
	}), [texts])

	const description: string = useMemo(() => (
		texts.find((text: Text) => text.key === 'home-info').value
	), [texts])

    const seo: Seo = useMemo(() => ({
        title: meta[0].title,
        description: meta[0].description,
        keywords: meta[0].keywords,
        lang: language
	}), [meta, language])

	const projectTexts: ProjectProps = useMemo(() => ({
		header: texts.find((text: Text) => text.key === 'projects-header').value,
		button: texts.find((text: Text) => text.key === 'projects-button-more').value
	}), [texts])

    const mainTeam = getMainTeamTexts(texts, siteImages)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

	const siteImage = useMemo(() => {
		const image = siteImages.find((image) => image.key === 'about-photo');

		return windowWidth > WINDOW_MOBILE_WIDTH
			? image.desktop.publicUrl
			: image.mobile.publicUrl
	}, [windowWidth, siteImages])

	const serivcePage = useMemo(() => ({
		header: texts.find((text: Text) => text.key === 'services-header').value,
		button: texts.find((text: Text) => text.key === 'services-button-more').value,
	}), [texts])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

	return (
		<MainLayout
			urlPrefix=''
			language ={language}
		>
			<SeoPage {...seo}/>
			<Slider
                images={sliderPictures}
            />
			<Description
                language={language}
                text={description}
            />
			{projects.length > 0 && (
				<ProjectList
					projects={projects}
					language={language}
					categories={categories}
					{...projectTexts}
				/>
			)}
			<ServicePage
				services={services}
				language={language}
				isHomePage
				{...serivcePage}
			/>
			<About
				language={language}
				image={siteImage}
				{...about}
			/>
			<MainTeam {...mainTeam}/>
			<PriceForm form={priceForm}/>
		</MainLayout>
	)
}

export default IndexTemplate