export const TYPE_TEXTS = {
    desktop : [
        'architecture',
        'construction',
        'real estate',
        'design',
        'legal support',
        'renovation'
    ],
    mobile : [
        'architecture',
        'construction',
        'real\nestate',
        'design',
        'legal\nsupport',
        'renovation'
    ]
}