import React, { useState, useEffect, useMemo } from 'react'
import Typed from 'react-typed'
import AwesomeSlider from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import { SliderPicture } from '../../../types'
import { WINDOW_MOBILE_WIDTH } from '../../../common/constants'
import { TYPE_TEXTS } from './constants'
import { NewCircleStudio } from '../../../icons'
import 'react-awesome-slider/dist/styles.css'
import './style.css'

interface SliderImages {
    images: SliderPicture[];
}

export const Slider = (
    {
        images
    }: SliderImages,
) => {
    const [ windowWidth, setWindowWidth ] = useState(727)

    const typeStrings = useMemo(() => (
        windowWidth < WINDOW_MOBILE_WIDTH
            ? TYPE_TEXTS.mobile
            : TYPE_TEXTS.desktop
    ), [WINDOW_MOBILE_WIDTH, windowWidth, TYPE_TEXTS])

    const typeOfDevice = useMemo(() => (
        windowWidth > WINDOW_MOBILE_WIDTH ? 'desktop' : 'mobile'
    ), [WINDOW_MOBILE_WIDTH, windowWidth])

    const AutoplaySlider = withAutoplay(AwesomeSlider)

    useEffect(() => {
        setWindowWidth(window.outerWidth)
    }, [])

    return (
        <section className='info'>
            <div className='info__header'>
                <div className='info__header-caption'>360°</div>
                <div className='info__header-title'>
                    <Typed
                        strings={typeStrings}
                        typeSpeed={40}
                        showCursor={false}
                        fadeOut
                        startDelay={0}
                        loop
                        contentType={null}
                    />
                </div>
            </div>
            <div className='info__carousel'>
                <AutoplaySlider
                    transitionDelay={300}
                    interval={5000}
                    buttons={false}
                    bullets={false}
                    cancelOnInteraction
                    mobileTouch
                    infinite
                    play
                >
                    {images.map((image) =>  (
                        <div
                            key={image.id}
                            data-src={`https://n-design.studio${image.poster[typeOfDevice].publicUrl}`}
                        />
                    ))}
                </AutoplaySlider>
            </div>
            <div className='info__circle'>
                <NewCircleStudio className='info__circle-image'/>
            </div>
        </section>
    )
}