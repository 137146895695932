import React from 'react'
import { AboutProps } from '../../../types'
import './About.css'

export const About = (
    {
        header,
        info,
        author,
        button,
        language,
        image
    }: AboutProps,
) => (
    <section className='about container'>
        <h2 className='about__header'>
            <a href={(language === 'ua') ? `/about` : `/${language}/about`}>
                {header}
            </a>
        </h2>
        <div className='about__description'>
            <div className='about__description-image'>
                <img
                    src={`https://n-design.studio${image}`}
                    alt='про нас'
                />
            </div>
            <div className='about__description-text'>
                <div className='info' dangerouslySetInnerHTML={{ __html: info }}></div>
                <div className='author'>— {author}</div>
                <div className="more">
                    <a href={`about`}>
                        <button className="btn btn-primary">{button}</button>
                    </a>
                </div>
            </div>
        </div>
    </section>
)